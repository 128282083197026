import React from 'react'
import { Link } from 'gatsby'

const randomNumber = (limit) => {
  return Math.floor(Math.random() * limit) + 1
}

const drawItem = (item, h, v) => {
  if (item.staticImg && item.url) {
    let str = `item h${h} v${v}`
    return (
      <div className={`item h2 v2`} style={{ display: 'grid' }}>
        <Link
          to={item.url}
          style={{
            gridArea: '1/1',
            // You can set a maximum height for the image, if you wish.
            // maxHeight: 600,
          }}
          layout="fullWidth"
        >
          {item.staticImg}
        </Link>

        <div
          style={{
            // By using the same grid area for both, they are stacked on top of each other
            gridArea: '1/1',
            position: 'relative',
            // This centers the other elements inside the hero component
            placeItems: 'end start',
            display: 'grid',
            padding: '0 0 0 20px',
            pointerEvents: 'none',
          }}
        >
          {/* Any content here will be centered in the component */}
          <h3>{item.title}</h3>
        </div>
      </div>
    )
  }
  return ''
}

// expected format:
// gridItemList = [
//   {
//     title: '',
//     subtitle: '',
//     author: '',
//     url: '',
//     staticImg: (<StaticImage/>),
//     html: ``,
//   },
// ]
const GridImageGallery = ({ gridItemList }) => {
  let list = gridItemList || []

  return (
    <div className="grid-image-gallery">
      {[1].map((n) => {
        return list.map((item, index) => drawItem(item, 3, 3))
      })}
    </div>
  )
}

export default GridImageGallery
