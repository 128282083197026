import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'

// find mdx documents
const mdxDocQuery = graphql`
  query MyQuery {
    mdx: allMdx(
      sort: { order: ASC, fields: [frontmatter___date] }
      limit: 100
    ) {
      nodes: nodes {
        fields {
          slug
        }
        frontmatter {
          date(fromNow: true)
          title
          author
          introduction
          thumbnailImg {
            publicURL
            childImageSharp {
              gatsbyImageData(width: 250)
            }
          }
        }
        id
      }
    }
  }
`

function drawThumbnailImg(post) {
  if (post?.frontmatter?.thumbnailImg?.childImageSharp) {
    return (
      <div className="thumbnail-image">
        <GatsbyImage
          image={getImage(post.frontmatter.thumbnailImg.childImageSharp)}
        />
      </div>
    )
  }
  return ''
}

const MdxDocList = ({ maxcount, showMoreLink }) => {
  const data = useStaticQuery(mdxDocQuery)
  const { nodes } = data.mdx

  return (
    <div id="doclist">
      {nodes.slice(0, maxcount || nodes.length).map((post) => (
        <Link className="item" to={'/thoughts/blog' + post.fields.slug}>
          {drawThumbnailImg(post)}
          <h2 className="center">{post.frontmatter.title}</h2>
          <span className="subtitle">
            <small>
              {post.frontmatter.author}, {post.frontmatter.date}
            </small>
          </span>
          <span className="description">{post.frontmatter.introduction}</span>
        </Link>
      ))}
      {showMoreLink && (
        <Link className="showmore" to="/thoughts/blog">
          Show More >
        </Link>
      )}
    </div>
  )
}
export default MdxDocList
