import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import GridImageGallery from '../layout/GridImageGallery'

// const recommendationsList = [
//   {
//     title: 'Cows Save the Planet',
//     subtitle: 'And Other Improbable Ways of Restoring Soil to Heal the Earth',
//     author: 'Judith D. Schwartz',
//     url: 'https://www.judithdschwartz.com/cows-save-the-planet',
//     staticImg: (
//       <StaticImage
//         src="../../assets/images/recommendations/CowsSavePlanet400x400.png"
//         alt="Cows Save The Planet"
//         layout="constrained"
//         placeholder="blurred"
//         width={400}
//       />
//     ),
//     html: `This is the book that sparked our big dream.
//     It brought all the ideas together into one meaningful message and priority:
//     soil health.`,
//   },
//   {
//     title: 'Water for the Recovery of the Climate',
//     subtitle: 'A New Water Paradigm',
//     author: 'M. Kravčík, J. Pokorny, J. Kohutiar, M. Kovac and E. Toth',
//     url: 'https://bio4climate.org/2017/05/13/water-for-the-recovery-of-the-climate-a-new-water-paradigm/',
//     staticImg: (
//       <StaticImage
//         src="../../assets/images/recommendations/NewWaterParadigm400x400.jpg"
//         alt="A New Water Paradigm"
//         layout="constrained"
//         placeholder="blurred"
//         width={400}
//       />
//     ),
//     html: `A dense read, but a powerful message!  This is our guiding truth`,
//   },
//   {
//     title: 'Changing Paradigms',
//     subtitle: 'Regenerative Agriculture: a Solution to our Global Crisis',
//     author: 'Charles Massy, Norm Smith',
//     url: 'https://www.youtube.com/watch?v=V6m-XlPnqxI',
//     staticImg: (
//       <StaticImage
//         src="../../assets/images/recommendations/ChangingParadigms400x400.jpg"
//         alt="Changing Paradigms"
//         layout="constrained"
//         placeholder="blurred"
//         width={400}
//       />
//     ),
//     html: `Changing Paradigms explores the power of regenerative agriculture in improving
//     the natural environment.  We have one generation, our generation, to take action and
//     change the paradigm. `,
//   },
// ]

const recommendationsList = [
  {
    url: 'https://www.judithdschwartz.com/cows-save-the-planet',
    staticImg: (
      <StaticImage
        src="../../assets/images/recommendations/CowsSavePlanet400x400.png"
        alt="Cows Save The Planet"
        layout="constrained"
        placeholder="blurred"
        width={400}
      />
    ),
  },
  {
    title: 'Water for the Recovery of the Climate',
    url: 'https://bio4climate.org/2017/05/13/water-for-the-recovery-of-the-climate-a-new-water-paradigm/',
    staticImg: (
      <StaticImage
        src="../../assets/images/recommendations/NewWaterParadigm400x400.jpg"
        alt="A New Water Paradigm"
        layout="constrained"
        placeholder="blurred"
        width={400}
      />
    ),
  },
  {
    title: 'Changing Paradigms',
    url: 'https://www.youtube.com/watch?v=V6m-XlPnqxI',
    staticImg: (
      <StaticImage
        src="../../assets/images/recommendations/ChangingParadigms400x400.jpg"
        alt="Changing Paradigms"
        layout="constrained"
        placeholder="blurred"
        width={400}
      />
    ),
  },
  {
    title: 'A Fence and an Owner',
    url: 'https://www.youtube.com/watch?v=wX_IwUfuu9A',
    staticImg: (
      <StaticImage
        src="../../assets/images/recommendations/AFenceAndAnOwner_400x400.jpg"
        alt="CA Fence and an Owner"
        layout="constrained"
        placeholder="blurred"
        width={400}
      />
    ),
  },
  {
    title: '',
    url: 'https://www.youtube.com/watch?v=uf8dF0agJEk',
    staticImg: (
      <StaticImage
        src="../../assets/images/recommendations/KissTheGround_400x400.jpg"
        alt="Kiss The Ground"
        layout="constrained"
        placeholder="blurred"
        width={400}
      />
    ),
  },
  {
    title: 'What is Holistic Management?',
    url: 'https://www.youtube.com/watch?v=8vKvDib_PKw',
    staticImg: (
      <StaticImage
        src="../../assets/images/recommendations/HolisticManagement_400x400.jpg"
        alt="What is Holistic Management?"
        layout="constrained"
        placeholder="blurred"
        width={400}
      />
    ),
  },
  {
    title: 'The Watershed Death Spiral',
    url: 'https://www.youtube.com/watch?v=TvHco_GL4Mo',
    staticImg: (
      <StaticImage
        src="../../assets/images/recommendations/Watershed_400x400.jpg"
        alt="The Watershed Death Spiral"
        layout="constrained"
        placeholder="blurred"
        width={400}
      />
    ),
  },
  {
    title: 'How Trees Bring Water',
    url: 'https://www.youtube.com/watch?v=oY8ds4BiG1A',
    staticImg: (
      <StaticImage
        src="../../assets/images/recommendations/TreesBringWater_400x400.jpg"
        alt="How Trees Bring Water"
        layout="constrained"
        placeholder="blurred"
        width={400}
      />
    ),
  },
  {
    title: "India's Water Revolution",
    url: 'https://www.youtube.com/watch?v=-8nqnOcoLqE&list=PLNdMkGYdEqOCgePyiAyBT0sh7zlr7xhz3',
    staticImg: (
      <StaticImage
        src="../../assets/images/recommendations/IndiasWaterRevolution_400x400.jpg"
        alt="India's Water Revolution"
        layout="constrained"
        placeholder="blurred"
        width={400}
      />
    ),
  },
]

const Recomendations = () => {
  return (
    <section id="recommendations">
      <GridImageGallery gridItemList={recommendationsList} />
    </section>
  )
}

export default Recomendations
