import React from 'react'
import { Link } from 'gatsby'
import Layout from '../../components/layout/Layout'
import MdxDocList from '../../components/thoughts/MdxDocList'
import Recommendations from '../../components/thoughts/Recommendations'

let helmut = {
  title: 'Treeland Trails Thoughts',
  description: [
    'Our thoughts, opinions, inspiration, recommendations, and blog',
  ].join('\n'),
  keywords: [''].join('\n'),
}

const Thoughts = () => {
  return (
    <Layout
      helmutTitle={helmut.title}
      helmutDescription={helmut.description}
      helmutKeywords={helmut.keywords}
    >
      <div id="thoughts" className="page-content">
        <div className="inner">
          <header className="">
            <h1>Thoughts and Opinions</h1>
          </header>
          <p>Here are some things we're thinking about...</p>

          <div id="project-blog-section">
            <div className="inner">
              <h2>Recent Projects and Blog</h2>
              <a class="anchor" id="projects"></a>
              <MdxDocList maxcount="2" />
            </div>
          </div>

          <div id="recommendation-section">
            <div className="inner">
              <h2>Recomendations</h2>
              <a class="anchor" id="recommendations"></a>
              <Recommendations />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

Thoughts.propTypes = {}

export default Thoughts
